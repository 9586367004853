<template lang="pug">
    .content-bdi
        .content-bdi__header
            HeadOrganization
        .content-bdi__body
            .grid.grid-cols-2.gap-8
                
                //- ATIVITES DISABLED
                //- .block
                    .flex.items-center.justify-between
                        h2.subheading(style="padding-bottom:0px") Aktivitas
                        //- b-button(text="Tambah Aktivitas" text_icon_left="file-plus" kind="primary" @click.native="openActivity = !openActivity")
                        b-button(
                            v-if="status_detail != 'loading'"
                            text="Tambah Aktivitas"
                            text_icon_left="file-plus"
                            kind="primary"
                            @click.native="openModalActivity()"
                        )
                    .container-timeline(v-if="status_detail === 'loading'")
                        .p-20.relative.text-center.w-full.h-full
                            b-loading(text="Memuat data aktivitas")
                    .container-timeline.relative(v-else)
                        .box-timeline.scrollbox(v-if="detail.organization_activity && detail.organization_activity.length > 0")
                            .box-timeline__inner.scrollbox-container
                                .timeline(v-for="item in detail.organization_activity")
                                    .timeline__time: span {{ datetimeFormat(item.activity_date) }}
                                    .timeline__content: p {{ trimmedStr(item.notes) }}
                        .absolute.text-center.w-full.h-full.flex.items-center.justify-center(v-else)
                            .block
                                b-icon.mb-4(name="info" size="2x")
                                p Belum ada aktivitas
                    //- .border.mt-8.border-gray-300.rounded
                        .box-timeline.scrollbox
                            .box-timeline__inner.scrollbox-container
                                .timeline(v-for="item in timeline")
                                    .timeline__time: span {{ item.time }}
                                    .timeline__content: p {{ item.content }}

                //- LAPORAN TERKAIT
                .block
                    .flex.items-center.justify-between.mb-8
                        h2.subheading(style="padding-bottom:0px") Berita &amp; Laporan
                        .flex.items-center.justify-between
                            .block.w-30.mr-3
                                .field.field--icon-right.w-full
                                    select.field-style(v-model="source_related_report" @change="getRelatedReport(true)")
                                        option(value="berita,laporan") Semua
                                        option(value="berita") Berita
                                        option(value="laporan") Laporan
                                    span.icon-field
                                        b-icon(name="chevron-down")
                            .box-calendar
                                b-icon(name="calendar")
                                date-range-picker(
                                    v-if="dateRange.startDate && dateRange.endDate"
                                    ref="picker"
                                    v-model="dateRange"
                                    @update="pickerUpdate($event)"
                                    :locale-data="{ firstDay: 1, format: 'dd mmmm yyyy', applyLabel: 'Terapkan', cancelLabel: 'Batalkan', }"
                                    :date-format="dateFormatPicker"
                                    opens="left"
                                    drops="up"
                                    :ranges="default_ranges"
                                    :autoApply="false"
                                    :showDropdowns="true"
                                )
                                    template(slot="input" slot-scope="picker" style="min-width: 350px;") {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    .box-report
                        //- ul
                            li(v-for="item in list_report")
                                button(v-if="item.source.toLowerCase() === 'laporan'" @click="openModalReportAction(item.title)")
                                    p(v-html="item.title")
                                    span.text-xs {{ item.source ? item.source : '' }} | {{ datetimeFormat(checkString(item.publishedAt, item.publish_date), 'YYYY-MM-DD HH:mm:ss',('DD MMM YYYY')) }}

                                a(v-else :href="checkString(item.url, item.source_url, '#')" target='_blank')
                                    p(v-html="item.title")
                                    span.text-xs {{ item.source ? item.source : '' }} | {{ datetimeFormat(checkString(item.publishedAt, item.publish_date), 'YYYY-MM-DD HH:mm:ss',('DD MMM YYYY')) }}
                        ul
                            li(v-for="item in list_report")
                                button(v-if="item.source.toLowerCase() === 'laporan'" @click="openModalReportAction(item.title)")
                                    .w-40
                                        .box-label-source
                                            b-icon(name="file-text" size="small").mr-2
                                            span.text-xs.font-bold {{ item.source ? item.source : '' }}
                                    .block
                                        p(v-html="item.title")
                                        span.text-xs {{ datetimeFormat(checkString(item.publishedAt, item.publish_date), 'YYYY-MM-DD HH:mm:ss',('DD MMM YYYY')) }}

                                a(v-else :href="checkString(item.url, item.source_url, '#')" target='_blank').flex.w-full
                                    .w-40
                                        .box-label-source
                                            b-icon(name="external-link" size="small").mr-2
                                            span.text-xs.font-bold {{ item.source ? item.source : '' }}
                                    .block
                                        p(v-html="item.title")
                                        span.text-xs {{ datetimeFormat(checkString(item.publishedAt, item.publish_date), 'YYYY-MM-DD HH:mm:ss',('DD MMM YYYY')) }}

                        template(v-if="list_report.length > 0 || status_related_report.status === 'loading'")
                            .relative.mt-5.mb-5(v-if="status_related_report.status === 'loading'")
                                b-loading(kind="circular")
                            template(v-else-if="list_report.length > 0 && (related_report && related_report._meta && related_report._meta.pagination && related_report._meta.pagination.has_next==true)")
                                .flex.items-center.justify-center.w-full
                                    b-button(text="Tampilkan berita atau laporan lainnya..." kind="tertiary" size="small" @click.native="loadMoreReport()")

                        .flex.items-center.justify-center(v-else style="min-height:414px")
                            span Berita atau laporan tidak ditemukan

                .block
                    h2.subheading Tokoh &amp; Organisasi Terkait
                    .card-graph.mt-5
                        template(v-if="status_detail==='loading'")
                            b-loading.b-loading-inherit(
                                text="Memuat data"
                            )
                        .box-network(v-else)
                            network.box-network__canvas(
                                ref="network"
                                :nodes="nodes"
                                :edges="edges"
                                :options="options"
                            )

            .py-10
                .flex.items-center.justify-between.mb-4
                    h2.subheading(style="padding-bottom:0px") Stream Tweet
                    //- .flex.items-center.justify-between
                    .block.w-30.mr-3(v-if="twitter_username !== ''")
                        .field.field--icon-right.w-full
                            select.field-style(v-model="limit_stream_tweet" @change="getStreamTweet()")
                                option(v-for="(item, index) in list_limit_stream_tweet" :key="index" :value="item") {{ item }}
                            span.icon-field
                                b-icon(name="chevron-down")
                .box-report
                    ul
                        li.flex.items-start.gap-6.mb-6(v-for="item in list_stream_tweet")
                            img.rounded-full(:src="item.user && item.user.profile_image_url ? item.user.profile_image_url : ''" :alt="item.user && item.user.name ? item.user.name : ''" @error="(getErrorImage($event,'profile'))")
                            .block.border-b.border-color-theme.pb-6
                                .flex.gap-2.items-center.mb-1
                                    strong.font-bold.block {{ item.user && item.user.name ? item.user.name : '-' }}
                                    span •
                                    span.text-xs {{ datetimeFormat(checkString(item.created_at), 'FFF MMM DD HH:mm:ss +0000 YYYY',('DD MMM YYYY')) }}
                                p.text-sm(v-html="item.text")

                    template(v-if="list_stream_tweet.length > 0 || status_stream_tweet.status === 'loading'")
                        .relative.mt-5.mb-5(v-if="status_stream_tweet.status === 'loading'")
                            b-loading(kind="circular")

                    .flex.items-center.justify-center(v-else style="min-height:414px")
                        span Tweet tidak ditemukan

            //- .py-10
                template(v-if="status_list_trend.status != 'loading' && list_trend.doc && list_trend.doc.length == 0")
                    .card-bg-theme.p-4.rounded
                        .w-full.h-80.flex.items-center.justify-center
                            .block
                                b-icon.mb-4(name="info" size="2x")
                                p Belum ada Trend
                template(v-else)
                    h2.subheading Trend Terkait

                    .bdi-trend
                        //- sidebar list of trend founded
                        .bdi-trend__sidebar.scrollbox
                            .bdi-trend-sidebar.bdi-trend-sidebar--organization.scrollbox-container
                                //- loading the trends first
                                b-loading.b-loading-inherit.mt-2(
                                    v-if="status_list_trend.status === 'loading'"
                                    text="Memuat informasi trend"
                                    kind="circular"
                                )
                                //- trends loaded
                                template(v-else)
                                    .w-full(v-if="list_data_topic.length > 0")
                                        button.list-trend(
                                            v-for="(item, key) in list_data_topic"
                                            @click="selectTopic(item, key)"
                                            :class="key == active_topic? 'active':''"
                                            v-tooltip="item.label"
                                        ) {{ item.label }}

                                        //- show more trend
                                        template.w-full.py-6.text-center(v-if="list_data_topic.length > 0 || status_list_trend.status === 'loading'")
                                            .relative(v-if="status_list_trend.status === 'loading'")
                                                b-loading(text="Menampilkan trend lainnya")

                                            template(v-else)
                                                b-button(v-if="list_trend.pagination && list_trend.pagination.hasNext && list_trend.pagination.hasNext === true" text="Tampilkan trend lainnya" kind="tertiary" @click.native="loadMore()")

                        //- Content for media
                        .bdi-trend__content
                            .bdi-trend-content.bdi-trend-content--media-on-organization(
                                :class="{'bdi-trend-content--open-calendar' : floatingCalendar}"
                            )
                                TopicMedia

                        //- Content for tweets
                        .bdi-trend__sidebar
                            .bdi-trend-sidebar.bdi-trend-sidebar--twitter-on-organization
                                TopicSocmed

                    //- DECKY INI YANG SEBELUMNYA
                    //- .box-activity-topic
                        .box-activity-topic__sidebar
                            .links-topic.scrollbox
                                .sidebar-activity-topic.scrollbox-container
                                    b-loading.b-loading-inherit.mt-2(
                                        v-if="status_list_trend.status === 'loading'"
                                        text="Memuat data trend"

                                    )
                                    template(v-else)
                                        //- template(v-if="list_data_topic.length > 0")
                                        ul.box-topic(v-if="list_data_topic.length > 0")
                                            li(
                                                v-for="(item, key) in list_data_topic"
                                                @click="selectTopic(item, key)"
                                                :class="key == active_topic? 'active':''"
                                                v-tooltip="item.label"
                                            ) {{ item.label }}
                                            //- li.flex-grow.text-left.truncate(v-for="item in list_data_topic") {{ item.label }}
                                            //- routerLink(v-for="item in list_data_topic" :to="'/manajemen/isu/'+ item.id +'/'+ encodeDetailData(item)") {{ item.label }}
                                        .block.text-center.mt-5.mb-5(v-if="list_data_topic.length > 0 || status_list_trend.status === 'loading'")
                                            .relative(v-if="status_list_trend.status === 'loading'")
                                                b-loading(text="Memuat data" kind="")

                                            template(v-else)
                                                b-button(v-if="list_trend.pagination && list_trend.pagination.hasNext && list_trend.pagination.hasNext === true" text="Lainnya..." kind="tertiary" @click.native="loadMore()")
                                        //- .text-center.mt-5.mb-5(v-else)
                                        //-     span Trend tidak ditemukan.
                                        //- routerLink( v-for="item in topicActivity" :to="item.url") {{ item.name }}
                        .box-activity-topic__content
                            .organization-topic
                                .organization-topic__media
                                    TopicMedia
                                .organization-topic__tweet
                                    TopicTwitter

        //- open popup
        .modal(v-bind:class="{'modal--show' : openActivity}")
            .modal-overlay
            form.modal-container(@submit.prevent="submit($event)")
                .modal-container__header
                    h2 Tambah Aktivitas
                    b-button(icon_only="x" @click.native="openActivity = !openActivity" type="button")
                .modal-container__body
                    .p-4.pb-0
                        .field.mb-4
                            input.field-style(
                                type="date"
                                placeholder="Pilih Waktu"
                                v-model="model.activity_date"
                            )
                            span.field-message.text-red-500(v-if="$v.model.activity_date.$error") Tanggal dibutuhkan.
                            //- span.field-message.text-red-500(v-if="error_activity_date !== ''") {{ error_activity_date }}
                        .field.mb-4
                            textarea.field-style(
                                placeholder="Tulis Keterangan disini"
                                style="min-height:200px"
                                v-model="model.notes"
                            )
                            span.field-message.text-red-500(v-if="$v.model.notes.$error") Keterangan dibutuhkan.
                            //- span.field-message.text-red-500(v-if="error_notes !== ''") {{ error_notes }}
                .modal-container__footer
                    b-button(text="Simpan" kind="primary" type="submit")
        //- open popup
        //- .modal(v-bind:class="{'modal--show' : openActivity}")
            .modal-overlay
            .modal-container
                .modal-container__header
                    h2 Tambah Aktivitas
                    b-button(icon_only="x" @click.native="openActivity = !openActivity")
                .modal-container__body
                    .p-4.pb-0
                        .field.mb-4
                            input.field-style(type="date" value="")
                        .field.mb-4
                            textarea.field-style(placeholder="Tulis Keterangan disini" style="min-height:200px")
                .modal-container__footer
                    b-button(text="Simpan" kind="primary" @click.native="openActivity = !openActivity")

        //- POPUP DETAIL LAPORAN
        .modal.modal--filled(v-bind:class="{'modal--show' : openModalReport}" style="z-index: 9999" ref="bigSearchDetailReport")
            .modal-container
                .modal-container__header
                    .flex-grow
                        h2 Laporan
                    b-button(icon_only="x" @click.native="openModalReport = !openModalReport; titleModalReport = '';" type="button")
                .modal-container__body
                    .relative.mt-10(v-if="status_detail_report === 'loading'")
                        b-loading(kind="circular")

                    template(v-else)
                        h2.subheading {{ titleModalReport }}
                        template(v-if="detail_report && Object.keys(detail_report).length > 0")
                            //- p Lorem ipsum dolor sit amet, cons
                            //- p(v-for="(item,index) in detail_report" :key="index") {{ item.description_raw ? item.description_raw : item.description }}
                            //- p(v-for="(item,index) in detail_report" :key="index") {{ item.description }}
                            div(v-for="(item,index) in detail_report" :key="index")
                                hr
                                p.mt-5.mb-5(v-html="JSON.parse(item.description_raw)")

                        .block.text-center.mb-5(v-else)
                            hr.mb-10
                            span Detail laporan tidak ditemukan.

</template>

<script>

import HeadOrganization from '@/pages/organization/HeadOrganization';
import TopicMedia from '@/pages/management/Media';
import TopicSocmed from '@/pages/management/Socmed';
import {mapState} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'BehaviorOfOrganization',
    components: {
        HeadOrganization,
        TopicMedia,
        TopicSocmed,
        DateRangePicker,
    },
    data() {
        return {
            openActivity: false,
            floatingCalendar: false,
            openModalReport: false,
            titleModalReport: '',
            timeline: [
                {
                    time: '16 Juni 20201',
                    content: 'Test Update 2',
                },
                {
                    time: '29-30 Maret 2021',
                    content: 'Bentrok fisik antara Angkatan Muda Partai Golkar (AMPG) kubu Yorrys dan kubu Aburizal Bakrie',
                },
                {
                    time: '29-30 Maret 2021',
                    content: 'Bentrok fisik antara Angkatan Muda Partai Golkar (AMPG) kubu Yorrys dan kubu Aburizal Bakrie',
                },
                {
                    time: '29-30 Maret 2021',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                },
                {
                    time: '29-30 Maret 2021',
                    content: 'Bentrok fisik antara Angkatan Muda Partai Golkar (AMPG) kubu Yorrys dan kubu Aburizal Bakrie',
                },
            ],
            source_related_report: 'berita,laporan',
            list_report: [],
            page_report: 1,
            dateRange: {
                // startDate: moment().subtract(6, 'days').toDate(),
                startDate: this.getDefaultFromDate(),
                endDate: moment().toDate(),
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

            twitter_username: '',
            list_stream_tweet: [],
            // page_stream_tweet: 1,
            list_limit_stream_tweet: [10, 25, 50, 100, 200],
            limit_stream_tweet: 10,

            // nodes: [
            //     {
            //         id: 1,
            //         label: 'Tokoh 1',
            //         shape: 'circle',
            //         color: 'rgb(21, 91, 240)',
            //         font: {color: 'white', size: 12},
            //     },
            //     {
            //         id: 2,
            //         label: 'Tokoh 2',
            //         shape: 'circle',
            //         color: 'rgb(21, 91, 240)',
            //         font: {color: 'white', size: 12},
            //     },
            //     {
            //         id: 3,
            //         label: 'Tokoh 3',
            //         shape: 'circle',
            //         color: 'rgb(21, 91, 240)',
            //         font: {color: 'white', size: 12},
            //     },
            //     {
            //         id: 4,
            //         label: 'Tokoh 4',
            //         shape: 'circle',
            //         color: 'rgb(21, 91, 240)',
            //         font: {color: 'white', size: 12},
            //     },
            //     {
            //         id: 5,
            //         label: 'Tokoh 5',
            //         shape: 'circle',
            //         color: 'rgb(21, 91, 240)',
            //         font: {color: 'white', size: 12},
            //     },
            //     {
            //         id: 6,
            //         label: 'Tokoh 6',
            //         shape: 'circle',
            //         color: 'rgb(21, 91, 240)',
            //         font: {color: 'white', size: 12},
            //     },
            // ],
            nodes: [],
            // edges: [
            //     {from: 1, to: 4},
            //     {from: 2, to: 4},
            //     {from: 3, to: 4},
            //     {from: 5, to: 4},
            //     {from: 5, to: 6},
            // ],
            edges: [],
            options: {
                nodes: {
                    borderWidth: 0,
                    widthConstraint: {
                        maximum: 150,
                    },
                },
                edges: {
                    color: 'rgb(21, 91, 240)',
                },
            },
            model: {
                activity_date: null,
                notes: null,
            },
            list_data_topic: [],
            limit: 100,
            page: 1,
            keyword: '',
            timeout: null,

            active_topic: null,
        };
    },
    validations: {
        model: {
            activity_date: {
                required,
            },
            notes: {
                required,
            },
        },
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState('organization', [
            'status_detail',
            'detail',
            'status_related_organizations',
            'related_organizations',
        ]),
        // for stream tweeter only
        ...mapState('actor', [
            'status_stream_tweet',
            'stream_tweet',
        ]),
        ...mapState('trend', [
            'status_list_trend',
            'list_trend',
        ]),
        ...mapState('news', [
            'status_related_report',
            'related_report',
            'status_detail_report',
            'detail_report',
        ]),
        // ...mapState('actor', {
        //     related_figures: 'related_figures',
        //     status_related_figures: 'status_related_figures',
        // }),

        filterStartDate() {
            return moment(this.dateRange.startDate).format('YYYY-MM-DD');
        },
        filterEndDate() {
            return moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
    },
    watch: {
        detail(newVal) {
            // if (this.nodes.id < 1) {
            try {
                const nodesTmp = {
                    id: 0,
                    label: newVal.name,
                    shape: 'circle',
                    color: '#dc2626',
                    font: {color: 'white', size: 12},
                };
                this.nodes.push(nodesTmp);
            } catch (error) {
                console.log(error);
            }
            // }
        },
        status_detail(newVal, oldVal) {
            if (newVal !== 'loading' && oldVal === 'loading') {
                // this.getData();
                // get Related ORG
                this.$store.dispatch('organization/getRelatedOrganizations', [(this.detail.name?this.detail.name:null)]);
                this.getRelatedReport(true);

                const twitter = this.detail.social_media.find((o) => o.media_type === 'twitter');
                const username = this.getUsernameSosmed(twitter.username);
                if (username !== '') {
                    // this.getRelatedTopic(username);
                    this.twitter_username = username;
                    this.getStreamTweet(username);
                }
            }
        },
        related_organizations(newVal) {
            if (newVal && newVal.length > 0) {
                for (let i = 0; i < newVal.length; i++) {
                    const clr = 'rgb(21, 91, 240)';
                    if (newVal[i].class === 'ORG') {
                        clr= 'rgb(17, 159, 161)';
                    }
                    const nodesLength = this.nodes.length;
                    const nodesTmp = {
                        id: nodesLength+1,
                        label: newVal[i].label,
                        shape: 'circle',
                        color: clr,
                        font: {color: 'white', size: 12},
                    };
                    const edgesTmp = {
                        from: nodesLength+1,
                        to: 0,
                    };
                    this.nodes.push(nodesTmp);
                    this.edges.push(edgesTmp);
                }
            }
        },
        related_report(newVal) {
            if (newVal.data && newVal.data.length > 0) {
                for (let i = 0; i < newVal.data.length; i++) {
                    this.list_report.push(newVal.data[i]);
                }
            }
        },
        stream_tweet(newVal) {
            if (newVal && newVal.length > 0) {
                // for (let i = 0; i < newVal.length; i++) {
                //     this.list_stream_tweet.push(newVal[i]);
                // }
                this.list_stream_tweet = newVal;
            }
        },
        list_trend(newVal) {
            if (newVal && newVal.doc && newVal.doc.length > 0) {
                for (let i = 0; i < newVal.doc.length; i++) {
                    this.list_data_topic.push(newVal.doc[i]);
                }
                if (this.page == 1) {
                    this.active_topic=0;
                    Event.$emit('getMediaOnDetail', this.encodeDetailData(newVal.doc[0]));
                }
            }
        },
    },
    methods: {
        openModalReportAction(title) {
            this.titleModalReport = title;
            this.$store.dispatch('news/getDetailReport', title);
            this.openModalReport = !this.openModalReport;
        },
        selectTopic(data, key) {
            if (key !== this.active_topic) {
                this.active_topic=key;
                Event.$emit('getMediaOnDetail', this.encodeDetailData(data));
            }
        },
        setEmptyModel(all=true) {
            this.model = {
                activity_date: null,
                notes: null,
            };
            // for (let key in Object.keys(this.model)) {
            //     if (all === true)
            //         this.model[Object.keys(this.model)[key]] = '';
            // }
        },
        openModalActivity() {
            this.openActivity = !this.openActivity;
            this.setEmptyModel(true);
        },
        reset(direct=false) {
            this.openActivity = false;
            // this.setEmptyModel(false);
            this.setEmptyModel(true);
            // reset vuelidate
            // this.$nextTick(() => { this.$v.$reset(); });
            // this.setErrors(true);
        },
        loadMore() {
            this.page += 1;
            this.getData();
        },
        async getData(reset=false) {
            if (this.status_detail !== 'loading') {
                await this.reset(true);
            }
            Event.$emit('getDataActor');
            if (reset) {
                this.list_data_topic = [];
                this.page = 1;
            }

            const state = {
                status: 'loading',
                cancel: null,
                code: null,
            };
            this.$store.commit('news/setStatusListNews', state);
            this.$store.commit('medsos/setStatusListTrending', state);
            this.$store.commit('medsos/setStatusListFeed', state);
            // this.$store.dispatch('trend/getListTrend', [this.limit, this.page, '', this.id, 'true'])
            //     .catch((resp) => {
            //         state.status = false;
            //         this.$store.commit('news/setStatusListNews', state);
            //         this.$store.commit('medsos/setStatusListTrending', state);
            //         this.$store.commit('medsos/setStatusListFeed', state);
            //     });
        },
        submit(e) {
            e.preventDefault();
            this.$v.$touch();

            if (this.$v.model.$invalid) {
                for (const key in Object.keys(this.$v.model)) {
                    const input = Object.keys(this.$v.model)[key];
                    if (input.includes('$')) return false;
                    if (this.$v.model[input].$error) {
                        break;
                    }
                }
                return;
            }
            Event.$emit('showLoading', true);

            const modelData = {organization_activity: this.detail.organization_activity};
            modelData.organization_activity.unshift(this.model);
            modelData.organization_activity = JSON.stringify(modelData.organization_activity);

            // update only
            this.$store.dispatch('organization/update', [this.id, modelData, 'activities'])
                .then((resp) => {
                    if (resp.result === 'success') {
                        this.$swal.fire({
                            icon: 'success',
                            // title: 'Tetap dihalaman ini?',
                            title: 'Data berhasil disimpan.',
                            confirmButtonText: 'Kembali',
                            // confirmButtonColor: '#DD6B55',
                            confirmButtonColor: '#5a80e7',
                            showCancelButton: false,
                            // cancelButtonText: 'Tidak',
                            allowOutsideClick: false,
                        })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    this.getData(true);
                                } else {
                                    this.$router.push({path: '/bdi/tokoh'});
                                }

                                Event.$emit('success', 'Data berhasil diperbarui!');
                            });
                    } else {
                        Event.$emit('error');
                    }
                });
        },
        loadMoreReport() {
            this.page_report += 1;
            this.getRelatedReport();
        },
        getRelatedReport(reset) {
            if (reset) {
                this.list_report = [];
                this.page_report = 1;
                this.$store.commit('news/setRelatedReport', {});
            }
            const query = this.detail.name ? this.detail.name : '';
            // const entityClass = 'ORG';
            const dateFrom = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            const dateTo = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            this.$store.dispatch('news/getRelatedReport', [this.limit_report, this.page_report, query, 'ORG', '', dateFrom, dateTo, this.source_related_report]);
        },
        async pickerUpdate(e) {
            // let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;
            // this.date_from = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.date_to = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            this.getRelatedReport(true);
        },
        async getStreamTweet(username = null) {
            // if (reset) {
            //     this.page_stream_tweet = 1;
            // }
            console.log(username)

            if (username === null) {
                username = this.twitter_username;
            } else {
                this.twitter_username = username;
            }

            this.list_stream_tweet = [];
            await this.$store.commit('actor/setStreamTweet', {});

            this.$store.dispatch('actor/getStreamTweet', [this.limit_stream_tweet, username]);
        },
    },
    mounted() {
        // if (this.status_detail === 'loading') {
        //     let status_related_figures = this.status_related_figures;
        //     status_related_figures.status = 'loading';
        //     await this.$store.commit('actor/setStatusRelatedFigures', status_related_figures);
        // }
        this.getData();
        if (this.status_detail !== 'loading') {
            if (this.nodes.length < 1) {
                const nodesTmp = {
                    id: 0,
                    label: this.detail.name,
                    shape: 'circle',
                    color: '#dc2626',
                    font: {color: 'white', size: 12},
                };
                this.nodes.push(nodesTmp);
            }
            // get Related ORG
            this.$store.dispatch('organization/getRelatedOrganizations', [(this.detail.name?this.detail.name:null)]);
            // if (this.detail.name) this.getRelatedReport(true);
            this.getRelatedReport(true);
            const twitter = this.detail.social_media.find((o) => o.media_type === 'twitter');
            const username = this.getUsernameSosmed(twitter.username);
            if (username !== '') {
                // this.getRelatedTopic(username);
                this.twitter_username = username;
                this.getStreamTweet(username);
            }
        }
    },
    created() {
        Event.$on('openFloatingCalendar', () => {
            this.floatingCalendar = !this.floatingCalendar;
        });
    },
    filters: {
        date: function(date) {
            return moment(date).locale('id').format('DD MMM YYYY');
        },
    },
};
</script>

<style lang="sass">

</style>
